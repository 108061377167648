<template>
  <div class="content">
    <!-- 顶部导航开始 -->
    <div class="top">
      <!-- 顶部导航图片开始 -->
      <img src="../assets/img/traffic-top-img.png" alt="" />
      <!-- 顶部导航图片结束 -->
      <!-- 内容容器开始 -->
      <div class="container top-nav">
        <!-- 顶部导航开始 -->
        <my-header class="hidden-xs-only" active="5"></my-header>
        <language-change2
          class="hidden-sm-and-up"
          active="5"
        ></language-change2>
        <!-- 顶部导航结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <!-- 顶部导航结束 -->
    <!-- tab 切换开始 -->
    <div class="tab-content hidden-xs-only">
      <div
        class="tab-item"
        :class="tab == 0 ? 'tab-active' : ''"
        @click="tab = 0"
      >
        {{ $t("message.subNav6_1") }}
      </div>
      <div
        class="tab-item"
        :class="tab == 1 ? 'tab-active' : ''"
        @click="tab = 1"
      >
        {{ $t("message.subNav6_2") }}
      </div>
      <div
        class="tab-item"
        :class="tab == 2 ? 'tab-active' : ''"
        @click="tab = 2"
      >
        {{ $t("message.subNav6_3") }}
      </div>
    </div>
    <div class="tab-content-sm hidden-sm-and-up">
      <!-- 标题部分开始 -->
      <div class="title">
        {{ $t("message.topNav6") }}
        <span class="small"></span>
        <span class="big"></span>
      </div>
      <!-- 标题部分结束 -->
      <!-- 内容开始 -->
      <div class="container">
        <el-row :gutter="20" class="tab-content-sm-btn">
          <el-col :span="8"
            ><div
              class="tab-content-sm-item"
              :class="tab == 0 ? 'tab-active-sm' : ''"
              @click="tab = 0"
            >
              {{ $t("message.subNav6_1") }}
            </div></el-col
          >
          <el-col :span="8"
            ><div
              class="tab-content-sm-item"
              :class="tab == 1 ? 'tab-active-sm' : ''"
              @click="tab = 1"
            >
              {{ $t("message.subNav6_2") }}
            </div></el-col
          >
          <el-col :span="8"
            ><div
              class="tab-content-sm-item"
              :class="tab == 2 ? 'tab-active-sm' : ''"
              @click="tab = 2"
            >
              {{ $t("message.subNav6_3") }}
            </div></el-col
          >
        </el-row>
      </div>
      <!-- 内容结束 -->
    </div>
    <!-- tab 切换结束 -->
    <!-- 区位图开始 -->
    <div v-show="tab == 0">
      <div class="Location">
        <div class="container">
          <!-- 标题部分开始 -->
          <div class="title">
            {{ $t("message.subNav6_1") }}
            <span class="small"></span>
            <span class="big"></span>
          </div>
          <!-- 标题部分结束 -->
          <!-- 图片开始 -->
          <div class="traffic-img">
            <img src="../assets/img/traffic-img.jpg" alt="" />
          </div>
          <!-- 图片结束 -->
          <!-- 内容开始 -->
          <div class="Location-content">
            <div class="Location-content-center">
              <!-- 单一内容开始 -->
              <div class="Location-content-item">
                <div class="Location-content-item-round"></div>
                <div class="Location-content-item-label">距离</div>
                <div class="Location-content-item-title">武昌火车站</div>
                <div class="Location-content-item-hr"></div>
                <div class="Location-content-item-number">14公里</div>
              </div>
              <!-- 单一内容结束 -->
              <!-- 单一内容开始 -->
              <div class="Location-content-item">
                <div class="Location-content-item-round"></div>
                <div class="Location-content-item-label">距离</div>
                <div class="Location-content-item-title">武汉火车站</div>
                <div class="Location-content-item-hr"></div>
                <div class="Location-content-item-number">33公里</div>
              </div>
              <!-- 单一内容结束 -->
            </div>
            <div class="Location-content-center">
              <!-- 单一内容开始 -->
              <div class="Location-content-item">
                <div class="Location-content-item-round"></div>
                <div class="Location-content-item-label">距离</div>
                <div class="Location-content-item-title">汉口火车站</div>
                <div class="Location-content-item-hr"></div>
                <div class="Location-content-item-number">12公里</div>
              </div>
              <!-- 单一内容结束 -->
              <!-- 单一内容开始 -->
              <div class="Location-content-item">
                <div class="Location-content-item-round"></div>
                <div class="Location-content-item-label">距离</div>
                <div class="Location-content-item-title">武汉天河机场</div>
                <div class="Location-content-item-hr"></div>
                <div class="Location-content-item-number">30公里</div>
              </div>
              <!-- 单一内容结束 -->
            </div>
          </div>
          <!-- 内容结束 -->
        </div>
      </div>
    </div>
    <!-- 区位图结束 -->
    <!-- 公交地铁线路图开始 -->
    <div v-show="tab == 1">
      <div class="middle">
        <div class="container">
          <!-- 标题部分开始 -->
          <div class="title">
            {{ $t("message.subNav6_2") }}
            <span class="small"></span>
            <span class="big"></span>
          </div>
          <!-- 标题部分结束 -->
          <!-- 地铁内容开始 -->
          <div class="middle-top">
            <!-- 左侧开始 -->
            <div class="middle-top-left">
              <!-- 标题开始 -->
              <div class="traffic-title">
                <div class="traffic-title-img">
                  <img src="../assets/svg/metro-icon-img.svg" alt="" />
                </div>
                <div class="traffic-title-msg">地铁路线</div>
              </div>
              <!-- 标题结束 -->
              <!-- 子内容开始 -->
              <div class="middle-item">
                <!-- 子标题开始 -->
                <div class="middle-item-title">
                  <span>武汉火车站</span>
                  <div class="middle-item-title-img">
                    <img src="../assets/svg/traffic-right-icon.svg" alt="" />
                  </div>
                  武汉国际博览中心
                </div>
                <!-- 子标题结束 -->
                <!-- 信息开始 -->
                <div class="middle-item-msg">乘坐地铁4号线转6号线直达</div>
                <!-- 信息结束 -->
              </div>
              <!-- 子内容结束 -->
              <!-- 子内容开始 -->
              <div class="middle-item">
                <!-- 子标题开始 -->
                <div class="middle-item-title">
                  <span>汉口火车站</span>
                  <div class="middle-item-title-img">
                    <img src="../assets/svg/traffic-right-icon.svg" alt="" />
                  </div>
                  武汉国际博览中心
                </div>
                <!-- 子标题结束 -->
                <!-- 信息开始 -->
                <div class="middle-item-msg">乘坐地铁2号线转6号线直达</div>
                <!-- 信息结束 -->
              </div>
              <!-- 子内容结束 -->
              <!-- 子内容开始 -->
              <div class="middle-item">
                <!-- 子标题开始 -->
                <div class="middle-item-title">
                  <span>武昌火车站</span>
                  <div class="middle-item-title-img">
                    <img src="../assets/svg/traffic-right-icon.svg" alt="" />
                  </div>
                  武汉国际博览中心
                </div>
                <!-- 子标题结束 -->
                <!-- 信息开始 -->
                <div class="middle-item-msg">乘坐地铁4号线转6号线直达</div>
                <!-- 信息结束 -->
              </div>
              <!-- 子内容结束 -->
              <!-- 子内容开始 -->
              <div class="middle-item-sm hidden-sm-and-up">
                <img src="../assets/img/traffic-img3.png" alt="" />
              </div>
              <!-- 子内容结束 -->
            </div>
            <!-- 左侧结束 -->
            <!-- 右侧开始 -->
            <div class="middle-top-right">
              <!-- 标题开始 -->
              <div class="traffic-title">
                <div class="traffic-title-img">
                  <img src="../assets/svg/car-icon-img.svg" alt="" />
                </div>
                <div class="traffic-title-msg">打车路线</div>
              </div>
              <!-- 标题结束 -->
              <!-- 内容开始 -->
              <div class="middle-top-right-content">
                <div class="middle-top-right-item">
                  <!-- 子内容开始 -->
                  <div class="middle-item">
                    <!-- 子标题开始 -->
                    <div class="middle-item-title">
                      <span>武汉天河国际机场</span>
                      <div class="middle-item-title-img">
                        <img
                          src="../assets/svg/traffic-right-icon.svg"
                          alt=""
                        />
                      </div>
                      武汉国际博览中心
                    </div>
                    <!-- 子标题结束 -->
                    <!-- 信息开始 -->
                    <div class="middle-item-msg">
                      距离33.4公里出租车用时56分钟打车费用约70元
                    </div>
                    <!-- 信息结束 -->
                  </div>
                  <!-- 子内容结束 -->
                  <!-- 子内容开始 -->
                  <div class="middle-item">
                    <!-- 子标题开始 -->
                    <div class="middle-item-title">
                      <span>武汉火车站</span>
                      <div class="middle-item-title-img">
                        <img
                          src="../assets/svg/traffic-right-icon.svg"
                          alt=""
                        />
                      </div>
                      武汉国际博览中心
                    </div>
                    <!-- 子标题结束 -->
                    <!-- 信息开始 -->
                    <div class="middle-item-msg">
                      距离31公里出租车用时50分钟打车费用约61元
                    </div>
                    <!-- 信息结束 -->
                  </div>
                  <!-- 子内容结束 -->
                </div>
                <div class="middle-top-right-item">
                  <!-- 子内容开始 -->
                  <div class="middle-item">
                    <!-- 子标题开始 -->
                    <div class="middle-item-title">
                      <span>武昌火车站</span>
                      <div class="middle-item-title-img">
                        <img
                          src="../assets/svg/traffic-right-icon.svg"
                          alt=""
                        />
                      </div>
                      武汉国际博览中心
                    </div>
                    <!-- 子标题结束 -->
                    <!-- 信息开始 -->
                    <div class="middle-item-msg">
                      距离9.6公里出租车用时24分钟打车费用约21元
                    </div>
                    <!-- 信息结束 -->
                  </div>
                  <!-- 子内容结束 -->
                  <!-- 子内容开始 -->
                  <div class="middle-item">
                    <!-- 子标题开始 -->
                    <div class="middle-item-title">
                      <span>汉口火车站</span>
                      <div class="middle-item-title-img">
                        <img
                          src="../assets/svg/traffic-right-icon.svg"
                          alt=""
                        />
                      </div>
                      武汉国际博览中心
                    </div>
                    <!-- 子标题结束 -->
                    <!-- 信息开始 -->
                    <div class="middle-item-msg">
                      距离13.1公里时间35分钟打车费用28元
                    </div>
                    <!-- 信息结束 -->
                  </div>
                  <!-- 子内容结束 -->
                </div>
              </div>
              <!-- 内容结束 -->
            </div>
            <!-- 右侧结束 -->
          </div>
          <!-- 地铁内容结束 -->
          <!-- 实时地铁线路导航内容开始 -->
          <div class="middle-item hidden-xs-only">
            <!-- 按钮开始 -->
            <div class="navigation-btn" @click="herfBaiDu">
              <div class="navigation-btn-content">
                <div class="navigation-btn-img">
                  <img src="../assets/svg/traffic-navigation-icon.svg" alt="" />
                </div>
                <div>点击查看 实时地铁线路导航</div>
              </div>
              <div class="navigation-metro">
                <img src="../assets/img/metro-icon-img2.png" alt="" />
              </div>
            </div>
            <!-- 按钮结束 -->
          </div>
          <!-- 实时地铁线路导航内容结束 -->
          <!-- 公交部分开始 -->
          <div class="middle-bottom">
            <!-- 左侧内容开始 -->
            <div class="middle-bottom-left">
              <!-- 标题开始 -->
              <div class="traffic-title">
                <div class="traffic-title-img">
                  <img src="../assets/svg/transit-icon-img.svg" alt="" />
                </div>
                <div class="traffic-title-msg">公交路线</div>
              </div>
              <!-- 标题结束 -->
              <!-- 子内容开始 -->
              <div class="middle-item">
                <!-- 信息开始 -->
                <div class="transit-msg">
                  直达国博公交：61路/517路/554路/580路/YX517路
                </div>
                <!-- 信息结束 -->
              </div>
              <!-- 子内容结束 -->
              <!-- 子内容开始 -->
              <div class="middle-item">
                <!-- 信息开始 -->
                <div class="middle-item-msg">
                  可乘坐 <br />
                  26/42/79/413/531/535/553/585/607/608/629/705/707/708/710/711/740/803/电5路到达钟家村，换乘地铁6号线至武汉国际博览中心
                </div>
                <!-- 信息结束 -->
              </div>
              <!-- 子内容结束 -->
              <!-- 子内容开始 -->
              <div class="middle-item">
                <!-- 信息开始 -->
                <div class="middle-item-msg">
                  可乘坐 <br />
                  26/42/79/377/402/411/524/585/629/704/705/726/727/801/806/w408/电1路/电4路/电5路到达武胜路，换乘地铁6号线至武汉国际博览中心
                </div>
                <!-- 信息结束 -->
              </div>
              <!-- 子内容结束 -->
              <!-- 子内容开始 -->
              <div class="middle-item">
                <!-- 信息开始 -->
                <div class="middle-item-msg">
                  可乘坐 <br />
                  26/42/79/377/402/411/524/585/629/704/705/726/727/801/806/w408/电1路/电4路/电5路到达东风公司，换乘地铁6号线至武汉国际博览中心
                </div>
                <!-- 信息结束 -->
              </div>
              <!-- 子内容结束 -->
            </div>
            <!-- 左侧内容结束 -->
            <!-- 右侧内容开始 -->
            <div class="middle-bottom-right hidden-xs-only">
              <img src="../assets/img/traffic-img3.png" alt="" />
            </div>
            <!-- 右侧内容结束 -->
          </div>
          <!-- 公交部分结束 -->
        </div>
      </div>
    </div>
    <!-- 公交地铁线路图结束 -->
    <!-- 馆内停车场示意图开始 -->
    <div v-show="tab == 2">
      <div class="signal">
        <div class="container">
          <!-- 标题部分开始 -->
          <div class="title">
            {{ $t("message.subNav6_3") }}
            <span class="small"></span>
            <span class="big"></span>
          </div>
          <!-- 标题部分结束 -->
          <!-- 次标题开始 -->
          <div class="signal-title">
            <div class="signal-title-left">
              <!-- 标题开始 -->
              <div class="sub-title1">
                <span></span>
                武汉国际博览中心停车区域参考
              </div>
              <!-- 标题结束 -->
            </div>
            <!-- <div class="signal-title-right">
              S
              <div class="signal-title-right-img">
                <img src="../assets/svg/compass-icon.svg" alt="" />
              </div>
              N
            </div> -->
          </div>
          <!-- 次标题结束 -->
          <!-- 内容开始 -->
          <div class="signal-img">
            <img src="../assets/img/traffic-img2.jpg" alt="" />
          </div>
          <!-- 内容结束 -->
          <!-- 提示开始 -->
          <div class="signal-content">
            <div class="signal-content-item">
              <span></span>
              <div>武汉国际博览中心停车区域共有车位约6000个</div>
            </div>
            <div class="signal-content-item">
              <span></span>
              <div>
                包含：展馆一层停车场、临时停车区、会议中心、洲际酒店一层、负一层停车场
              </div>
            </div>
          </div>
          <!-- 提示结束 -->
        </div>
      </div>
    </div>
    <!-- 馆内停车场示意图结束 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      tab: 2, //0、区位图，1、公交地铁线路图，2、馆内停车场示意图
    };
  },
  mounted() {
    let num = this.$route.query.tab;
    if (num) {
      this.tab = num;
    }
  },
  methods: {
    // 打开VR
    herfBaiDu() {
      window.open(
        " https://map.baidu.com/poi/%E6%AD%A6%E6%B1%89%E5%9B%BD%E9%99%85%E5%8D%9A%E8%A7%88%E4%B8%AD%E5%BF%83/@12717874.296524737,3548155.109037264,15.8z?uid=295d925e9a988b265b3ac880&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl"
      );
    },
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style scoped>
.traffic-img {
  margin: 20px 0;
}

.traffic-img img {
  width: 100%;
}

.Location-content {
  margin-bottom: 20px;
}

.Location-content-item {
  width: 100%;
  display: flex;
  align-items: center;
}

.Location-content-item-round {
  width: 8px;
  height: 8px;
  background-color: #999999;
  border-radius: 50%;
  margin-right: 16px;
}

.Location-content-item-label {
  color: #999999;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 18px;
  margin-right: 20px;
}

.Location-content-item-title {
  width: 90px;
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 20px;
  margin-right: 16px;
}

.Location-content-item-hr {
  width: 70px;
  height: 1px;
  background-color: #dddddd;
  margin-right: 20px;
}

.Location-content-item-number {
  color: #004bc7;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24px;
}

.middle-top-left {
  margin-top: 30px;
}

.middle-top-right {
  margin-top: 40px;
}

.traffic-title {
  display: flex;
  position: relative;
}

.traffic-title-img {
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.traffic-title-img img {
  width: 100%;
}

.traffic-title-msg {
  width: 100%;
  height: 40px;
  color: #333333;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  background: linear-gradient(270deg, rgba(214, 236, 255, 0) 0%, #d5ecff 100%);
  box-sizing: border-box;
  line-height: 40px;
  margin-left: 20px;
  padding-left: 30px;
}

.middle-item {
  margin-top: 30px;
}

.middle-item-sm {
  margin-top: 30px;
}

.middle-item-sm img {
  width: 100%;
}

.middle-item-title {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  display: flex;
  margin-bottom: 10px;
}

.middle-item-title span {
  color: #004bc7;
  font-weight: bold;
}

.middle-item-title-img {
  margin: 0 10px;
}

.middle-item-msg {
  width: 100%;
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  word-break: break-all;
}

.navigation-btn-content {
  color: #ffffff;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  display: flex;
  padding: 12px 0 0 44px;
}

.navigation-btn-img {
  margin-right: 16px;
}

.navigation-metro {
  position: absolute;
  right: 16px;
  bottom: 0;
}

.middle-bottom {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
}

.middle-bottom-left {
  width: 400px;
}

.transit-msg {
  color: #004bc7;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 28px;
  word-wrap: break-word;
}

@media (min-width: 768px) {
  .traffic-img {
    margin: 40px 0 40px;
  }

  .Location-content-center {
    display: flex;
    justify-content: center;
  }

  .Location-content-center + .Location-content-center {
    margin: 30px 0 80px;
  }

  .Location-content-item {
    width: 480px;
    display: flex;
    align-items: center;
  }

  .Location-content-item-round {
    width: 8px;
    height: 8px;
    background-color: #999999;
    border-radius: 50%;
    margin-right: 16px;
  }

  .Location-content-item-label {
    color: #999999;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 24px;
    margin-right: 20px;
  }

  .Location-content-item-title {
    width: 120px;
    color: #333333;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 24px;
    margin-right: 20px;
  }

  .Location-content-item-hr {
    width: 80px;
    height: 1px;
    background-color: #dddddd;
    margin-right: 20px;
  }

  .Location-content-item-number {
    color: #004bc7;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 24px;
  }

  .middle-top {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }

  .middle-top-left {
    width: 360px;
    margin-top: 0;
  }

  .middle-top-right {
    width: 800px;
    margin-top: 0;
  }

  .traffic-title {
    display: flex;
  }

  .traffic-title-img {
    width: 50px;
    height: 50px;
  }

  .traffic-title-msg {
    width: 100%;
    height: 50px;
    color: #333333;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: linear-gradient(
      270deg,
      rgba(214, 236, 255, 0) 0%,
      #d5ecff 100%
    );
    box-sizing: border-box;
    line-height: 50px;
    margin-left: 25px;
    padding-left: 41px;
  }

  .middle-item {
    margin-top: 40px;
  }

  .middle-item-title {
    color: #333333;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 30px;
    display: flex;
    margin-bottom: 10px;
  }

  .middle-item-title span {
    color: #004bc7;
    font-weight: bold;
  }

  .middle-item-title-img {
    margin: 0 10px;
  }

  .middle-item-msg {
    color: #333333;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 30px;
    word-break: break-all;
  }

  .middle-top-right-content {
    display: flex;
    justify-content: space-between;
  }

  .navigation-btn {
    width: 100%;
    height: 79px;
    background-color: #1691fa;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
  }

  .navigation-btn-content {
    color: #ffffff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    padding: 28px 0 0 40%;
  }

  .navigation-btn-img {
    margin-right: 16px;
  }

  .navigation-metro {
    position: absolute;
    right: 30%;
    bottom: 0;
  }

  .middle-bottom {
    margin: 80px 0;
    display: flex;
    justify-content: space-between;
  }

  .middle-bottom-left {
    width: 360px;
  }

  .transit-msg {
    color: #004bc7;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 28px;
    word-wrap: break-word;
  }

  .middle-bottom-right {
    width: 800px;
  }

  .middle-bottom-right img {
    width: 100%;
  }
}

.signal-title {
  margin: 20px 0;
  display: flex;
}

.signal-title-left {
  width: 600px;
}

.signal-title-right {
  color: #004bc7;
  font-size: 16px;
  font-family: Roboto;
  font-weight: bold;
  line-height: 28px;
  display: flex;
}

.signal-title-right-img {
  margin: 0 10px;
}

.signal-img img {
  width: 100%;
}

.signal-content {
  margin: 20px 0;
}

.signal-content-item {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.signal-content-item + .signal-content-item {
  margin-top: 10px;
}

.signal-content-item span {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #004bc7;
  margin-right: 20px;
  display: inline-block;
}

.signal-content-item div {
  flex: 1;
}

@media (min-width: 768px) {
  .signal-title {
    margin: 40px 0 0px;
    display: flex;
  }

  .signal-title-left {
    width: 600px;
  }

  .signal-title-right {
    color: #004bc7;
    font-size: 24px;
    font-family: Roboto;
    font-weight: bold;
    line-height: 28px;
    margin-top: 12px;
    display: flex;
  }

  .signal-content {
    margin: 40px 0 80px;
  }

  .signal-content-item {
    color: #333333;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  .signal-content-item + .signal-content-item {
    margin-top: 12px;
  }

  .signal-content-item span {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #004bc7;
    margin-right: 20px;
    display: inline-block;
  }
}
</style>
